const doHideWithDelay = (targetEl, popoverRef, hideDelay) => {
  const hideTimeoutId = setTimeout(() => {
    popoverRef.hide()
    targetEl.dataset.tId = ''
  }, hideDelay || DEFAULT_HIDE_DELAY)
  targetEl.dataset.tId = `${hideTimeoutId}`
}
const doShow = ($event, targetEl, popoverRef, showDelay) => {
  setTimeout(() => {
    popoverRef.show($event, targetEl)
  }, showDelay || DEFAULT_SHOW_DELAY)

  if (targetEl.dataset.tId) {
    clearTimeout(targetEl.dataset.tId)
    targetEl.dataset.tId = ''
  }
}
const DEFAULT_SHOW_DELAY = 0
const DEFAULT_HIDE_DELAY = 250

/*
Write some documentation and examples here
 */

const PopoverTrigger = {
  updated: (targetEl, bindings) => {
    const { modifiers, value } = bindings
    if (!value) return
    const popoverRef = value.popoverRef ? value.popoverRef : value
    const showDelay = value.showDelay ? value.showDelay : DEFAULT_SHOW_DELAY
    const hideDelay = value.hideDelay ? value.hideDelay : DEFAULT_HIDE_DELAY

    const hoverTrigger = modifiers.hover
    const focusTrigger = modifiers.focus
    const clickTrigger = modifiers.click

    // actual popover component isn't mounted yet
    if (!popoverRef) return

    if (hoverTrigger && targetEl.dataset.ptHover !== 'true') {
      targetEl.addEventListener('mouseenter', ($event) => {
        doShow($event, targetEl, popoverRef, showDelay)
        // popoverRef.show($event, targetEl)
      })
      targetEl.addEventListener('mouseleave', () => {
        doHideWithDelay(targetEl, popoverRef, hideDelay)
      })

      targetEl.dataset.ptHover = 'true'

      // popoverRef.$defaultPT.pt = popoverRef.$props.pt || {}
      // popoverRef.$props.pt.root = popoverRef.$props.pt.root || {}
      popoverRef.$_attrsPT.root = popoverRef.$_attrsPT.root || {}
      popoverRef.$_attrsPT.root.onMouseenter = ($event) => {
        doShow($event, targetEl, popoverRef, showDelay)
      }
      popoverRef.$_attrsPT.root.onMouseleave = () => {
        doHideWithDelay(targetEl, popoverRef, hideDelay)
      }
    }
    if (focusTrigger && targetEl.dataset.ptFocus !== 'true') {
      targetEl.addEventListener('focus', ($event) => {
        doShow($event, targetEl, popoverRef, showDelay)
      })
      targetEl.addEventListener('blur', () => {
        doHideWithDelay(targetEl, popoverRef, hideDelay)
      })
      targetEl.setAttribute('tabindex', '0')
      targetEl.dataset.ptFocus = 'true'
    }
    if (clickTrigger && targetEl.dataset.ptClick !== 'true') {
      targetEl.addEventListener('click', ($event) => {
        popoverRef.toggle($event, targetEl)
      })

      targetEl.dataset.ptClick = 'true'
    }
  }
}

export const PopoverTriggerDirective = {
  install (Vue, options) {
    Vue.directive('popover-trigger', PopoverTrigger)
  }
}
