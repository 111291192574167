const index = {
  header: {
    background: 'transparent',
    hover: {
      background: 'transparent'
    },
    active: {
      background: 'transparent',
      hover: {
        background: 'transparent'
      }
    }
  },
  content: {
    background: 'transparent'
  }
}

export { index as default }
