import { ref } from 'vue'

const promptId = 'prompt-modal'
const modalVisible = ref(false)
const asyncResolve = ref(null)

const defaultOptions = {
  title: 'Just One Thing',
  size: 'sm',
  centered: true,
  'body-class': 'white-space-pre-line',
  text: '',
  description: '',
  placeholder: '',
  defaultValue: '',
  forms: null
}
const globalOptions = ref({
  ...defaultOptions
})
export const setOptions = (options) => {
  globalOptions.value = {
    ...globalOptions.value,
    ...options
  }
}
export const setVisible = (visible) => {
  modalVisible.value = visible
}
export const setAsyncResolve = (resolveFn) => {
  asyncResolve.value = resolveFn
}

export default function usePrompt () {
  return {
    promptId,
    modalVisible,
    asyncResolve,
    globalOptions
  }
}
