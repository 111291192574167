import { ref } from 'vue'

const confirmAlertId = 'confirm-modal'
const modalVisible = ref(false)
const asyncResolve = ref(null)

const defaultOptions = {
  title: 'Are you sure?',
  'ok-title': 'Yes I\'m sure',
  size: 'sm',
  centered: true,
  'auto-focus-button': 'ok',
  'body-class': 'white-space-pre-line'
}
const globalOptions = ref({
  ...defaultOptions
})
export const setOptions = (options) => {
  globalOptions.value = {
    ...globalOptions.value,
    ...options
  }
}
export const setVisible = (visible) => {
  modalVisible.value = visible
}
export const setAsyncResolve = (resolveFn) => {
  asyncResolve.value = resolveFn
}

export default function useConfirmAlert () {
  return {
    confirmAlertId,
    modalVisible,
    globalOptions,
    asyncResolve
  }
}
