// Design tokens to better match bootstrap buttons FOR NOW until we migrate to prime vue.. 
// the pv buttons were looking too different compared to bv buttons so i put this in the global sheet until we can swap out everything
const index = {
  padding: {
    x: '0.75rem',
    y: '0.375rem'
  },
  border: {
    radius: '0.25rem'
  },
  label: {
    font: {
      weight: '400'
    }
  }
}

export { index as default }
