export const Ai = (flujax) => ({
  async chatPrompt (promptWithHistory) {
    return flujax.post('/ai/chat/prompt', promptWithHistory)
  },

  async chatPromptPlanType (planType, item) {
    return flujax.post(`/ai/chat/${planType}`, item)
  },

  async balance () {
    return flujax.post('ai/chat/balance')
  },

  async slash () {
    return flujax.post('ai/chat/slash')
  }
})
