import {bidStrategyLabels, criterionLabels} from '../constants'
import {convertFromCamelCase, convertFromCapsUnderscore} from 'core-ui/plugins/CoreUiFilters.js'

export const removeWhitespace = (str) => {
  if (typeof str === 'string') {
    return str.replace(/\s/g, '')
  } else {
    return str
  }
}
export const convertCsvToJs = (csv) => {
  let result = []
  if (csv && typeof csv === 'string' && csv.length > 0) {
    const lines = csv.split('\n')

    // assign headers to a map where
    // key = index, value = header value
    // eg { 0: 'CustomerId', 1: 'CPC' }
    const headers = Object.assign({}, lines.shift().split(','))

    // map each line to an object where
    // key = header value, value = comma-delimited value
    result = lines
      .filter(line => line.length > 0)
      .map(line => {
        return line.split(',') // split the line on comma
          .reduce((rowObj, val, idx) => { // reduce the split array to an object k: header, v: value
            rowObj[headers[idx]] = val
            return rowObj
          }, {})
      })

    // TODO for DEV ONLY!!
    if (result.length === 0) {
      result = [Object.values(headers).reduce((obj, header) => {
        obj[header] = '---'
        return obj
      }, {})]
    }
  }
  return result
}
export const convertJsToCsv = (array) => {
  if (array && array.length > 0) {
    const header = Object.keys(array[0])
    const replacer = (key, value) => value === null ? '' : value
    let csv = array
      .map(row => header
        .map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift(header.join(','))
    csv = csv.join('\n')

    return csv
  }
  return ''
}
export const friendlyArrayOutput = (arr, lang = ['is', 'are']) => {
  let output = ''
  const len = arr.length
  const quote = '"'

  if (len === 1) {
    output += `${quote}${arr[0]}${quote} ${lang[0]}`
  } else {
    for (let i = 0; i < len; i++) {
      if (i === len - 1) {
        output += `and ${quote}${arr[i]}${quote} ${lang[1]}`
      } else {
        output += `${quote}${arr[i]}${quote}, `
      }
    }
  }

  return output
}
export const flattenObject = (inputObj) => {
  const returnObj = {}

  for (const i in inputObj) {
    if (!inputObj.hasOwnProperty(i)) continue

    if ((typeof inputObj[i]) === 'object') {
      const flatObject = this.flattenObject(inputObj[i])

      for (const j in flatObject) {
        if (!flatObject.hasOwnProperty(j)) continue

        returnObj[`${i}.${j}`] = flatObject[j]
      }
    } else {
      returnObj[i] = inputObj[i]
    }
  }
  return returnObj
}
export const upperCaseCharOne = str => (`${str[0].toUpperCase()}${str.substring(1)}`)
export const lowercase = val => val?.toLowerCase()
export const hide = () => ''
export const removeHTTP = (uri = '') => {
  if (uri && uri !== '') {
    return uri.replace(/htt.+:\/\//, '')
  } else {
    return ''
  }
}
export const prependPath = (uri) => {
  const path = uri
  if (path && path !== '') {
    return `/${path}`
  }
  return ''
}
export const labelize = (str) => {
  if (!str) {
    return str
  }
  const tabNames = {
    displayads: 'Display Ads',
    accountdata: 'Account Data',
    blueprintdata: 'Blueprint Data',
    blueprinttweaks: 'Blueprint Tweaks',
    subaccounts: 'Sub-Accounts',
    userlists: 'Audiences',
    trackingtags: 'Tracking Tags',
    locationdata: 'Location Data',
    nameoverrides: 'Name Overrides',
    productcatalogs: 'Catalogs',
    pagePosts: 'Facebook Page Posts',
    crawlerdata: 'Crawler Data',
    sharedsets: 'Keyword Sets',
    'start-and-end-dates': 'Start/End Dates',
    targetingAndAudiences: 'Targeting & Audiences'
  }
  return tabNames[str] || convertFromCamelCase(str)
}
export const labelizePlanType = (str) => {
  if (!str) {
    return str
  }
  return str
    .replace('adgroup', 'Ad Group')
    .replace('adGroup', 'Ad Group')
    .replace('creative', 'Ad')
    .replace('keyword', 'Keyword')
    .replace('campaign', 'Campaign')
    .replace('account', 'Account')
    .replace('budget', 'Budget')
    .replace('extension', 'Extension')
    .replace('blockedKeywords', 'Blocked Keywords')
    .replace('userList', 'User List')
    .replace('audience', 'Audience')
}
export const labelizeProductDimension = (str) => {
  if (!str) return str
  if (str.startsWith('BIDDING_CATEGORY')) {
    return 'Google Product Category'
  } else if (str.startsWith('PRODUCT_TYPE')) {
    return 'Product Type'
  } else {
    switch (str) {
      case 'CUSTOM_ATTRIBUTE_0':
        return 'Custom Label 0'
      case 'CUSTOM_ATTRIBUTE_1':
        return 'Custom Label 1'
      case 'CUSTOM_ATTRIBUTE_2':
        return 'Custom Label 2'
      case 'CUSTOM_ATTRIBUTE_3':
        return 'Custom Label 3'
      case 'CUSTOM_ATTRIBUTE_4':
        return 'Custom Label 4'
      case 'BRAND':
        return 'Brand'
      case 'CANONICAL_CONDITION':
        return 'Condition'
      case 'OFFER_ID':
        return 'Item ID'
      case 'CHANNEL':
        return 'Channel'
      case 'CHANNEL_EXCLUSIVITY':
        return 'Channel Exclusivity'
      default:
        return str
    }
  }
}
export const lastInstanceAfter = (str, delimiter = '.') => {
  if (str.indexOf(delimiter) === -1) {
    return str
  } else {
    const arr = str.split(delimiter)
    return arr[arr.length - 1]
  }
}

export const labelizeGeneric = (str, delimiters = ['_']) => {
  if (!str) return ''
  const regex = new RegExp(`[${delimiters.join('')}]`, 'gi')
  return str
    .replace(regex, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
export const labelizeCreativeFieldName = (str) => {
  if (!str) {
    return str
  }
  switch (str) {
    case 'body':
    case 'bodies': return 'Primary Text'
    case 'title': return 'Headline'
    case 'titles': return 'Headlines'
    case 'formPlanId': return 'Lead Form'
    case 'productScopePlanId': return 'Product Set'
    default:
      return str
  }
}
export const labelizePacingStrategy = (str) => {
  if (!str) {
    return str
  }
  switch (str) {
    case 'STANDARD_BUSINESS_WEEK': return 'Front Loaded Business Week'
    case 'METRIC_TRACKER': return 'Metric Based'
    default:
      return convertFromCapsUnderscore(str)
  }
}
export const labelizeBidStrategy = (str) => {
  if (!str) return str
  const label = bidStrategyLabels[str]
  if (label) return label
  return str
}
export const stripDynamicKeywordInsertion = (str) => {
  if (!str || str === '') {
    return ''
  }
  if (Array.isArray(str)) {
    return str.map(s => stripDynamicKeywordInsertion(s))
  }
  const regex = /\{keyword:[^}]*\}/gi

  return str.replace(regex, function (match) {
    return match.replace(/\{keyword:/gi, '').replace(/\}/g, '')
  })
}

export const evaluateLengthAfterInsertion = (string) => {
  if (typeof string !== 'string') return string
  return stripDynamicKeywordInsertion(string)
    .replace(/{LOCATION(.*)}/g, (match) => {
      const fallback = match.replace(/{LOCATION\((.*)\)/g, '').replace('}', '')
      if (fallback.length > 0 && fallback.includes(':')) {
        return fallback.replace(':', '')
      } else {
        return '0000000000' // Google uses 10 character count when there is no fallback
      }
    }) // google counts location insertion as 10 chars
    .replace(/{COUNTDOWN\(.*?\)}/g, '0000000') // google counts countdown insertion as 7 chars
    .length
}
export const cleanJSON = (str) => {
  let result = str.replace(/\s\s+/g, ' ') // multiple spaces
  result = result.replace(/["][\s]/g, '"') // leading spaces
  result = result.replace(/["][\\][\s]/g, '"\\') // leading spaces in encoded
  result = result.replace(/[\s]["]/g, '"') // trailing spaces
  result = result.replace(/[\s][\\]["]/g, '\\"') // trailing spaces in encoded

  return result
}
export const truncate = (string, allowedLength = 50, truncateBeginning = false) => {
  if (!string) return ''
  if (string.length < allowedLength) {
    return string
  }
  return truncateBeginning ? `...${string.slice(string.length - allowedLength, string.length)}` : `${string.slice(0, allowedLength)}...`
}
export const singular = (string) => {
  if (string?.length > 0 && string.lastIndexOf('s') === string.length - 1) {
    return string.substring(0, string.length - 1)
  }
  return string
}
export const removeMarkdown = (string) => {
  if (!string) return ''
  return string.replace(/[#*`_\[\]\(\)!]|(http(s?):)([\/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g, '')
}
export const labelizeCriterion = (criterionType) => {
  return criterionLabels.hasOwnProperty(criterionType) ? criterionLabels[criterionType] : convertFromCapsUnderscore(criterionType)
}

export const BackpackFiltersPlugin = {
  install (Vue, options) {
    Vue.config.globalProperties.$filters = {
      ...(Vue.config.globalProperties.$filters || {}),
      removeWhitespace,
      convertCsvToJs,
      convertJsToCsv,
      friendlyArrayOutput,
      flattenObject,
      upperCaseCharOne,
      lowercase,
      hide,
      removeHTTP,
      prependPath,
      labelize,
      labelizePlanType,
      labelizeProductDimension,
      lastInstanceAfter,
      labelizeGeneric,
      labelizeCreativeFieldName,
      labelizePacingStrategy,
      labelizeBidStrategy,
      stripDynamicKeywordInsertion,
      evaluateLengthAfterInsertion,
      cleanJSON,
      truncate,
      singular,
      removeMarkdown,
      labelizeCriterion
    }
  }
}
