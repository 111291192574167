export const bidStrategyLabels = {
  MANUAL_CPC: 'Manual CPC',
  MANUAL_CPM: 'Viewable CPM',
  MAXIMIZE_CONVERSION_VALUE: 'Maximize Conversion Value',
  MAXIMIZE_CONVERSIONS: 'Maximize Conversions',
  TARGET_CPA: 'Target CPA',
  TARGET_ROAS: 'Target ROAS',
  TARGET_SPEND: 'Maximize Clicks',
  LOWEST_COST: 'Lowest Cost',
  LOWEST_COST_WITH_BID_CAP: 'Lowest Cost with Bid Cap',
  TARGET_IMPRESSION_SHARE: 'Target Impression Share',
  COST_CAP: 'Cost Cap',
  PORTFOLIO: 'Portfolio Level Strategy',
  MANUAL_CPV: 'Manual CPV',
  TARGET_CPV: 'Target CPV',
  TARGET_CPM: 'Target CPM',
  MANUAL_CPA: 'Manual CPA',
  ENHANCED_CPC: 'Enhanced CPC'
}
