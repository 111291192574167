<script setup>
const props = defineProps({
  title: {
    type: String
  },
  fixedHeader: {
    type: Boolean,
    default: false
  }
})
const ptOptions = {
  header: typeof props.title === 'undefined' ? { style: { 'padding-bottom': '0' } } : {}
}
</script>

<template>
  <p-panel :header="props.title"
         :pt="ptOptions"
         :class="{ 'p-panel-fixed-header': fixedHeader }">
    <template v-if="$slots.icons" #icons>
      <slot name="icons"></slot>
    </template>
    <template v-if="$slots.header" #header>
      <slot name="header"></slot>
    </template>
    <slot name="default"></slot>
  </p-panel>
</template>

<style lang="scss">
.p-panel.p-panel-fixed-header {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .p-panel-content-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
