import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo } from 'nuxt/app'
const INDEX_PAGE_PATH = '/welcome/'

export default defineNuxtRouteMiddleware((to, from) => {
  // const { route } = context
  // const route = useRoute()
  const nuxtApp = useNuxtApp()
  if (to.path === '/') {
    if (nuxtApp.$store.getters.user?.defaultHomePage?.length > 0) {
      return navigateTo(nuxtApp.$store.getters.user.defaultHomePage)
    } else {
      return navigateTo(INDEX_PAGE_PATH)
    }
  }
})
