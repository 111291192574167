/**
 * Toast Plugin uses prime vue toast to show alerts on screen
 * Utilize with $toast(args)
 *
 * Parameters
 * @param {string | object} message - text of toast
 * @param {type} type - severity level of toast
 * @param {object} options
 *   @property {string} options.title - title of toast
 *   @property {number} options.timeout - timeout in ms, or 0 for no timeout
 *   @property {object} bugsnagData - any additional bugsnag data to attach
 *                                    (currently only sends on messages > 200 characters)
 */

const toast = function ({ $pvToast, $filters, $bugsnag }) {
  const getTitle = (title, message) => {
    let titleString = typeof title === 'string' ? title : ''

    if (message?.requestId) {
      if (titleString) {
        titleString += ' - '
      }
      titleString += `Error ID: ${message.requestId}`
    }

    return titleString
  }
  const getErrorId = (message) => {
    return message?.requestId || null
  }

  const getText = (message) => {
    let messageString

    if (typeof message.message === 'string') {
      messageString = message.message
    } else if (typeof message === 'string') {
      messageString = message
    } else {
      messageString = JSON.stringify(message)
    }

    return messageString
  }

  const incrementToast = (text) => {
    const existing = document.querySelectorAll('.p-toast-detail')
    const matchingIndex = Array.from(existing).findIndex(toast => {
      const span = toast.querySelector('span.count')

      if (span) span.remove()
      const isMatch = toast && toast.textContent.includes(text)
      if (span) toast.appendChild(span)

      return isMatch
    })

    if (matchingIndex >= 0) {
      const detailElement = existing[matchingIndex]
      const span = detailElement.querySelector('span.count')

      if (!span) {
        // If span doesn't exist, create a new span with the text 'x1'
        detailElement.insertAdjacentHTML('beforeend', '<span class="count font-weight-bold"> x2</span>')
      } else {
        // If span exists, increment its value
        const currentCount = parseInt(span.textContent.replace('x', ''), 10) || 0
        span.textContent = ` x${currentCount + 1}`
      }
      return true
    }
    return false
  }

  return function (message, type = 'warning', options = {}) {
    if (!message) return
    const text = getText(message)

    // increment an existing toast
    if (incrementToast(text)) {
      return
    }

    // map props to pv severity
    // todo: move out so we can reuse
    const map = {
      success: {
        severity: 'success'
      },
      info: {
        severity: 'info'
      },
      warn: {
        severity: 'warn'
      },
      warning: {
        severity: 'warn'
      },
      hacker: {
        severity: 'error'
      },
      danger: {
        severity: 'error'
      },
      error: {
        severity: 'error'
      }
    }
    const level = map[type] || map.warn

    // if (text.length > 200) { // send bugsnag to track any extra long toasts // comment out for a sec, this aint working
    //   $bugsnag(new Error('Toast Error Message Truncated'), {
    //     severity: 'info',
    //     ...options?.bugsnagData,
    //     metadata: {
    //       ...options?.bugsnagData?.metadata,
    //       text
    //     }
    //   })
    // }

    const defaultTimeout = level.severity === 'success' ? 3000 : 7500

    $pvToast.add({
      severity: level.severity,
      summary: getTitle(options?.title, message),
      detail: $filters.truncate(text, 200),
      life: !isNaN(options?.timeout) ? options.timeout : defaultTimeout,
      errorId: getErrorId(message)
    })
  }
}

export const toastNuxtInstall = (nuxtApp) => {
  const global = nuxtApp.vueApp.config.globalProperties

  global.$pvToast = global.$toast // reassign prime vues toast to $pvToast
  global.$toast = toast({
    $pvToast: global.$pvToast, // provide pvToast
    $filters: global.$filters, // provide filters
    $bugsnag: global.$bugsnag // provide bugsnag
  })

  nuxtApp.provide('toast', global.$toast) // this line adds $toast to nuxtApp, so that it can be accessed across all composables, plugins, and thes store
}
