const index = {
  tab: {
    borderColor: '{content.border.color}',
    hoverBorderColor: '{content.border.color}',
    activeBorderColor: '{surface.600}',
    color: '{text.color}',
    hoverColor: '{text.hover.color}',
    activeColor: '{primary.hover.color}',
    padding: '.875rem .75rem',
    gap: '0.5rem'
  },
  tablist: {
    background: 'transparent'
  },
  tabpanel: {
    background: 'transparent',
    color: '{content.color}'
  }
}

export { index as default }
