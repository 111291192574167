export const User = (flujax) => ({
  async emailResubscribe () {
    return flujax.post('/user/clearUnsubscribe')
  },
  helpCenterGet (parentIds, search) {
    const params = []
    if (parentIds) {
      params.push(`parentIds=${parentIds}`)
    }
    if (search) {
      params.push(`search=${search}`)
    }
    return flujax.post(`/user/helpcenter/get?${params.join('&')}`)
  }
})
