<script setup>
import { computed, inject } from 'vue'
import { convertButtonVariant, convertSize } from 'core-ui/primeVueBridge/convertBvToPrime.js'

const $props = defineProps({
  // bv props for Reverse Support
  active: {
    type: Boolean,
    default: false
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    validator: (val) => ['sm', 'md', 'lg', 'small', 'large'].indexOf(val) > -1 // small and large are primevue options
  },
  pressed: {
    // TODO: pressed and active are the same thing...pick one and migrate.
    type: Boolean,
    default: false
  },
  variant: {
    type: String // this is both a bv and a primevue prop
  },

  // primeVue props
  label: {
    type: String
  },
  icon: {
    type: String
  },
  iconPos: {
    type: String,
    validator: (val) => ['left', 'right', 'top', 'bottom'].indexOf(val) > -1,
    default: 'left'
  },
  iconClass: {
    type: [String, Array, Object]
  },
  badge: {
    type: String
  },
  badgeClass: {
    type: [String, Array, Object]
  },
  badgeSeverity: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  },
  loadingIcon: {
    type: String
  },
  as: {
    type: [String, Object],
    default: 'button'
  },
  asChild: {
    type: Boolean,
    default: false
  },
  link: {
    type: Boolean,
    default: false
  },
  severity: {
    type: String
  },
  raised: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  text: {
    type: Boolean,
    default: false
  },
  outlined: {
    type: Boolean,
    default: false
  },
  fluid: { // this is equivalent to setting the block prop
    type: Boolean,
    default: false
  }
})
const $emit = defineEmits(['click'])
const onClick = ($event) => {
  $emit('click', $event)
}

const getTastyButtonGroup = inject('getTastyButtonGroup')
const tastyButtonGroupParent = computed(() => {
  if (getTastyButtonGroup) {
    return getTastyButtonGroup()
  }
  return null
})

const computedSize = computed(() => {
  if ($props.size) {
    return convertSize($props.size)
  }
  if (tastyButtonGroupParent.value?.size) {
    return convertSize(tastyButtonGroupParent.value.size)
  }
})

const computedPrimeSeverity = computed(() => {
  if ($props.severity) {
    return $props.severity
  } else if ($props.variant && !['outlined', 'text', 'link'].includes($props.variant)) {

    if ($props.variant.includes('-')) {
      return convertButtonVariant($props.variant.slice($props.variant.indexOf('-') + 1))
    }

    return convertButtonVariant($props.variant)
  }
})
const computedPrimeVariant = computed(() => {
  if (!$props.variant) return
  if (['outlined', 'text', 'link'].includes($props.variant)) {
    return $props.variant
  } else if ($props.variant.startsWith('outline')) {
    return $props.active ? null : 'outlined'
  } else if ($props.variant.startsWith('ghost')) {
    return $props.active ? null : 'text'
  } else if ($props.variant.startsWith('link')) {
    return 'link'
  }
})

</script>

<template>

  <p-button :class="[{ active: active || pressed }]"
            :label="label"
            :icon="icon"
            :iconPos="iconPos"
            :iconClass="iconClass"
            :badge="badge"
            :badgeClass="badgeClass"
            :badgeSeverity="badgeSeverity"
            :loading="loading"
            :loadingIcon="loadingIcon"
            :as="as"
            :asChild="asChild"
            :link="link"
            :severity="computedPrimeSeverity"
            :raised="raised"
            :rounded="rounded"
            :text="text"
            :outlined="outlined"
            :size="computedSize"
            :variant="computedPrimeVariant"
            :fluid="fluid || block"
            :disabled="disabled"
            @click="onClick">
    <slot></slot>
  </p-button>

</template>

<style scoped lang="scss">

 @mixin activeClass($severity) {
   .p-button-#{$severity}.active:not(:disabled) {
     @include activeStyle($severity)
   }
 }
 @mixin activeStyle($severity) {
   background: var(--p-button-#{$severity}-active-background);
   border: 1px solid var(--p-button-#{$severity}-active-border-color);
   color: var(--p-button-#{$severity}-active-color)
 }

 .p-button.active:not(:disabled) {
   @include activeStyle(primary)
 }

 @include activeClass(primary);
 @include activeClass(secondary);
 @include activeClass(success);
 @include activeClass(warn);
 @include activeClass(danger);
 @include activeClass(info);
 @include activeClass(contrast);

</style>
