import FluencyIcon from 'core-ui/components/icons/fluencyIcon.vue'
import TastyButtonInline from 'core-ui/components/tasty/tastyButtonInline.vue'
import TastyButtonNavLink from 'core-ui/components/tasty/tastyButtonNavLink.vue'
import TastyAlert from 'core-ui/components/tasty/tastyAlert.vue'
import TastyListGroup from 'core-ui/components/tasty/tastyListGroup.vue'
import TastyListGroupItem from 'core-ui/components/tasty/tastyListGroupItem.vue'
import TastyRow from 'core-ui/components/tasty/tastyRow.vue'
import TastyCol from 'core-ui/components/tasty/tastyCol.vue'
import TastyCard from 'core-ui/components/tasty/tastyCard.vue'
import TastyAccordion from 'core-ui/components/tasty/tastyAccordion.vue'
import TastyButton from 'core-ui/components/tasty/tastyButton.vue'
import TastyButtonGroup from 'core-ui/components/tasty/tastyButtonGroup.vue'
import TastyModal from '../components/tasty/tastyModal.vue'
import TastyCollapse from '../components/tasty/tastyCollapse.vue'
import TastyDropdown from 'core-ui/components/tasty/tastyDropdown.vue'
import TastyDropdownItem from 'core-ui/components/tasty/tastyDropdownItem.vue'
import TastyDropdownText from 'core-ui/components/tasty/tastyDropdownText.vue'
import TastyDropdownHeader from 'core-ui/components/tasty/tastyDropdownHeader.vue'
import TastyDropdownContent from 'core-ui/components/tasty/tastyDropdownContent.vue'
import TastyDropdownDivider from 'core-ui/components/tasty/tastyDropdownDivider.vue'
import TastyDropdownGroup from 'core-ui/components/tasty/tastyDropdownGroup.vue'
import InfoTooltip from 'core-ui/components/common/infoTooltip.vue'
import { vue3Only } from 'core-ui/assets/js/vueCompatFlags'

export const FluencyComponentsPlugin = {
  install (Vue, options) {
    Vue.component('FluencyIcon', FluencyIcon)
    Vue.component('InfoTooltip', InfoTooltip)

    TastyButtonInline.compatConfig = vue3Only()
    TastyButtonNavLink.compatConfig = vue3Only()
    TastyAlert.compatConfig = vue3Only()
    TastyListGroup.compatConfig = vue3Only()
    TastyListGroupItem.compatConfig = vue3Only()
    TastyRow.compatConfig = vue3Only()
    TastyCol.compatConfig = vue3Only()
    TastyCard.compatConfig = vue3Only()
    TastyAccordion.compatConfig = vue3Only()

    TastyDropdown.compatConfig = vue3Only()
    TastyDropdownItem.compatConfig = vue3Only()
    TastyDropdownText.compatConfig = vue3Only()
    TastyButton.compatConfig = vue3Only()
    TastyButtonGroup.compatConfig = vue3Only()

    TastyModal.compatConfig = vue3Only()
    TastyCollapse.compatConfig = vue3Only()
    TastyDropdown.compatConfig = vue3Only()
    TastyDropdownItem.compatConfig = vue3Only()
    TastyDropdownText.compatConfig = vue3Only()
    TastyDropdownHeader.compatConfig = vue3Only()
    TastyDropdownContent.compatConfig = vue3Only()
    TastyDropdownDivider.compatConfig = vue3Only()
    TastyDropdownGroup.compatConfig = vue3Only()

    Vue.component('t-button-inline', TastyButtonInline)
    Vue.component('t-button-nav-link', TastyButtonNavLink)
    Vue.component('t-list-group', TastyListGroup)
    Vue.component('t-list-group-item', TastyListGroupItem)
    Vue.component('t-row', TastyRow)
    Vue.component('t-col', TastyCol)
    Vue.component('t-alert', TastyAlert)
    Vue.component('t-card', TastyCard)
    Vue.component('t-accordion', TastyAccordion)
    Vue.component('t-dropdown', TastyDropdown)
    Vue.component('t-dropdown-item', TastyDropdownItem)
    Vue.component('t-dropdown-text', TastyDropdownText)
    Vue.component('t-button', TastyButton)
    Vue.component('t-btn', TastyButton)
    Vue.component('t-button-group', TastyButtonGroup)
    Vue.component('t-modal', TastyModal)
    Vue.component('t-collapse', TastyCollapse)
    Vue.component('t-dropdown', TastyDropdown)
    Vue.component('t-dropdown-item', TastyDropdownItem)
    Vue.component('t-dropdown-item-button', TastyDropdownItem)
    Vue.component('t-dropdown-text', TastyDropdownText)
    Vue.component('t-dropdown-header', TastyDropdownHeader)
    Vue.component('t-dropdown-content', TastyDropdownContent)
    Vue.component('t-dropdown-divider', TastyDropdownDivider)
    Vue.component('t-dropdown-group', TastyDropdownGroup)
  }
}

export default {
  install (Vue, options) {
    FluencyComponentsPlugin.install(Vue, options)
  }
}
