<script setup>

import { provide } from 'vue'

const $props = defineProps({
  size: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

/*
if you set the size and disabled props on the group,
 this Provide function passes it down to the tasty-buttons
 eg:
  <t-button-group size="small" disabled>
    <t-button />
    <t-button />
  </t-button-group>

  is equivalent to:
  <t-button-group>
    <t-button size="small" disabled />
    <t-button size="small" disabled />
  </t-button-group>
 */
provide('getTastyButtonGroup', () => ({
  size: $props.size,
  disabled: $props.disabled
}))


</script>

<template>
  <p-button-group>
    <slot></slot>
  </p-button-group>
</template>

<style scoped lang="scss">

</style>
