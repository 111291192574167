export const Audience = (flujax) => ({
  async getCustomerAudiences () {
    return flujax.post('/audience/getCustomerAudiences')
  },
  async setCustomerAudience (sharedAudiencePlan) {
    return flujax.post('/audience/setCustomerAudience', sharedAudiencePlan)
  },
  async refreshCustomerAudience (sharedAudiencePlan) {
    return flujax.post('/audience/refreshCustomerAudience', sharedAudiencePlan)
  },
  async getEligibleSharedAudiences (accountPlanId) {
    return flujax.post(`/audience/getEligibleSharedAudiences?accountPlanId=${accountPlanId}`)
  },
  async utilizeSharedAudience (accountPlanId, sharedAudiencePlanId) {
    return flujax.post(`/audience/utilizeSharedAudience?accountPlanId=${accountPlanId}&sharedAudiencePlanId=${sharedAudiencePlanId}`)
  },
  async fetchRtbAudiences (opt = {}) {
    const { accountPlanId, advertisingChannelId } = opt
    let urlParams = null

    if (accountPlanId) {
      urlParams = `accountPlanId=${accountPlanId}`
    }

    if (advertisingChannelId) {
      urlParams = `advertisingChannelId=${advertisingChannelId}`
    }

    if (urlParams) {
      const [targeting, remarketing] = await Promise.all([
        flujax.post(`/user-list/getDDCRTBTargeting?${urlParams}`),
        flujax.post(`user-list/getDDCRTBRemarketing?${urlParams}`)
      ])
      return [...targeting, ...remarketing]
    }
  }
})
