import { TRACKING_KEY_MAP, TRACKING_CATEGORY_PROPS } from '../../assets/js/constants/tracking'

/**
 * TRACK PLUGIN
 *
 * Use this.$track.event() to capture any dynamic data in the app.
 *
 * We have 2 places to send events to at the moment - our own Fluency DB (audit db)
 * and GA4. Using this method will send it to both, and format the event meta keys
 * and values as needed.
 *
 * There are some pre-built event parameters for Fluency added in tracking.js. More info there.
 *
 * Notes on GA4
 * - Meta keys must be snake case
 * - GA4 only collects reporting if the data key is registered as a custom dimension on the GA side.
 *   This means if you are trying to add custom meta on an event and want to be able to run reports on it in GA4, you will
 *   have to use an existing custom dimension defined in GA Analyics -> Admin - Data Display -> Custom Definitions
 */

/**
 * Service Definitions
 * - Fluency Events
 * - GA4
 */
const BaseAnalyticsService = () => {
  return {
    name: '',
    meta: '',
    category: '',
    element: null,

    setName (name) {
      this.name = name || ''
    },

    setMeta (meta) {
      this.meta = meta || {}
    },

    setCategory (category) {
      this.category = category || ''
    },

    setElement (element) {
      this.element = element || null
    },

    addBaseMetaForCategory () {
      const vue = this.vue
      const category = this.category?.toLowerCase()

      const mergedProps = [
        ...TRACKING_CATEGORY_PROPS.global,
        ...(TRACKING_CATEGORY_PROPS[category] || [])
      ]

      const baseMeta = mergedProps.reduce((acc, prop) => {
        return {
          ...acc,
          ...this.createProp(vue, prop, this.element)
        }
      }, {})

      const combinedMeta = {
        ...baseMeta,
        ...this.meta // let users override the properties if needed
      }

      // remove empty values
      for (const key in combinedMeta) {
        if (combinedMeta.hasOwnProperty(key) && (combinedMeta[key] === undefined || combinedMeta[key] === null)) {
          delete combinedMeta[key]
        }
      }

      this.meta = combinedMeta
    },

    createProp (store, prop, element) {
      if (TRACKING_KEY_MAP[prop]) {
        return { [prop]: TRACKING_KEY_MAP[prop](store, element) }
      }

      return {}
    },

    sendEvent () {},

    send () {
      this.addBaseMetaForCategory()
      this.sendEvent()
    }
  }
}

const FluencyEvents = () => {
  return {
    ...BaseAnalyticsService(),
    sendEvent () {
      this.vue.$res.set.trackUserEvent(this.name, this.meta)
    }
  }
}

const GA4 = () => {
  return {
    ...BaseAnalyticsService(),
    setName (name) {
      this.name = snakeCase(name)
    },
    setMeta (meta) {
      if (typeof meta !== 'object' || meta === null) {
        this.meta = {}
        return
      }

      const snakeCaseMeta = {}

      for (const key in meta) {
        const snakeCaseKey = snakeCase(key)
        snakeCaseMeta[snakeCaseKey] = meta[key]
      }

      this.meta = snakeCaseMeta
    },
    sendEvent () {
      gtag('event', this.name, this.meta)
    }
  }
}

/**
 * Utility
 */
const snakeCase = (string) => {
  return string
    .replace(/ /g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
}

/**
 * GA4 Specific Function to Send Events
 */
function gtag () {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

/**
 * Register Services
 */
const ANALYTICS_SERVICES = [
  FluencyEvents(),
  GA4()
]

/**
 * Expose a way to track an event with name, meta, category, and element.
 * This will send the data to any services defined in ANALYTICS_SERVICE
 *
 */
const track = (vue) => {
  return {
    /**
     *
     * @param {string} name - Name of the event, ex: 'Action Button'
     * @param {object} meta - Any associated data to track, ex: {'label': 'Upload'}
     * @param {string} category - Optional - There are some properties that get sent automatically if you attach a category.
     *                         See TRACKING_CATEGORY_PROPS in tracking.js
     * @param {Node} element - Optional - Attach a JS node if available to attempt to auto fill properties.
     *                         See TRACKING_KEY_MAP in tracking.js
     */
    event: (name, meta, category, element) => {
      ANALYTICS_SERVICES.forEach((service) => {
        service.vue = vue

        service.setName(name)
        service.setMeta(meta)
        service.setCategory(category)
        service.setElement(element)
        service.send()
      })
    }
  }
}

export const trackNuxtInstall = (nuxtApp) => {
  /** START -- GA4 TAG INITIALIZATION **/
  const tagId = 'G-NF9LKYLHKX'
  const store = nuxtApp.$store
  const globalParams = {
    customer_id: store.getters.customer?.customerId,
    user_registry_id: store.getters.user?.userRegistryId,
    user_id: store.getters.user?.userRegistryId,
    is_internal_user: store.getters.isFluencyUser
  }

  gtag('js', new Date())
  gtag('config', tagId, globalParams)
  /** END -- GA4 TAG INITIALIZATION **/
  nuxtApp.vueApp.config.globalProperties.$track = track(nuxtApp)
  nuxtApp.provide('track', nuxtApp.vueApp.config.globalProperties.$track)
}
