export const AdTemplate = (flujax) => ({
  async get (adTemplate) {
    return await flujax.fetch.post('/adtemplate/get', adTemplate)
  },
  async list () {
    return await flujax.fetch.post('/adtemplate/list')
  },
  async listDraft () {
    return await flujax.fetch.post('/adtemplate/list/draft')
  },
  async listIneligible () {
    return await flujax.fetch.post('/adtemplate/list/ineligible')
  },
  async assetContents (asset) {
    return await flujax.fetch.post('/adtemplate/get/templateAssetContents', asset)
  },
  async evaluateTags (creativeTypeDefinition, accountPlanId) {
    return await flujax.fetch.post(`/adtemplate/mergeCreativeTypeDefinitionWithAccountData?accountPlanId=${accountPlanId}`, creativeTypeDefinition)
  },
  async buildAdOptions (adTemplate, accountPlanId) {
    return await flujax.fetch.post(`/adtemplate/mergeAdTemplateWithAccountData?accountPlanId=${accountPlanId}`, adTemplate)
  },
  async zip (adTemplateId, adSpec, basePath) {
    return await flujax.fetch.post(`/adtemplate/zip?adTemplateId=${adTemplateId}${basePath ? '&basePath=' + basePath : ''}`, adSpec, { responseType: 'blob', returnFullResponse: true })
  },
  async set (adTemplate) {
    return await flujax.set.post('/adtemplate/set', adTemplate)
  },
  async promoteDraft (adTemplate) {
    return await flujax.set.post('/adtemplate/promote/draft', adTemplate)
  },
  async setAssets (assets, adTemplateId) {
    return await flujax.set.post(`/adtemplate/set/templateAssets?adTemplateId=${adTemplateId}`, assets)
  },
  async deleteAssets (assets) {
    return await flujax.set.post('/adtemplate/delete/assets', assets)
  },
  async productSample (accountPlanId, productScopePlanId) {
    return await flujax.fetch.post(`/template/payload/sample?accountPlanId=${accountPlanId}&productScopePlanId=${productScopePlanId}`)
  }
})
