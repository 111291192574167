export const DSP = (flujax) => ({
  async fetchViewabilityProviders () {
    return flujax.get('/dsp/viewability/providers')
  },
  async fetchViewabilityStandards (providerName) {
    let path = '/dsp/viewability/standards'
    if (providerName) {
      path += `?providerName=${providerName}`
    }
    return flujax.get(path)
  },
  async fetchCrossDeviceVendors () {
    return flujax.get('/dsp/crossDeviceVendors?partnerId=18')
  },
  async fetchDeviceTypes () {
    return flujax.get('/dsp/devices')
  },
  async getCampaignViewability (accountPlanId, campaignPlanId) {
    return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/viewability`)
  },
  async setCampaignViewability (accountPlanId, campaignPlanId, data) {
    return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/viewability`, data)
  },
  async getVideoConfig (accountPlanId, campaignPlanId) {
    return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/videoConfig`)
  },
  async setVideoConfig (accountPlanId, campaignPlanId, data) {
    return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/videoConfig`, data)
  },
  async getCampaignDeviceTypes (accountPlanId, campaignPlanId) {
    return flujax.get(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/devices`)
  },
  async setCampaignDeviceTypes (accountPlanId, campaignPlanId, data) {
    return flujax.post(`/dsp/${accountPlanId}/campaign/${campaignPlanId}/devices`, data)
  },
  async getTargetSets (targetSetPlan) {
    return flujax.post('/audience/getTargetSetPlanDto', targetSetPlan)
  },
  async setTargetSet (targetSetPlan) {
    return flujax.post('/audience/setTargetSetPlanDto', targetSetPlan)
  },
  async setDomainsForAdGroup (accountPlanId, campaignPlanId, adGroupId, domainList) {
    return flujax.post(`/dsp/${accountPlanId}/${campaignPlanId}/${adGroupId}/domains`, domainList)
  },
  async getDomainsForAdGroup (accountPlanId, campaignPlanId, adGroupId) {
    return flujax.get(`/dsp/${accountPlanId}/${campaignPlanId}/${adGroupId}/domains`)
  },
  async setDomainsForCampaign (accountPlanId, campaignPlanId, domainList) {
    return flujax.post(`/dsp/${accountPlanId}/${campaignPlanId}/domains2`, domainList)
  },
  async getDomainsForCampaign (accountPlanId, campaignPlanId) {
    return flujax.get(`/dsp/${accountPlanId}/${campaignPlanId}/domains2`)
  },
  async getDomainsForAccount (accountPlanId) {
    return flujax.get(`/dsp/getDomainsForAccount?accountPlanId=${accountPlanId}`)
  },
  async getDomainsForCustomer (customerId) {
    return flujax.get(`/dsp/getDomainsForCustomer2?customerId=${customerId}`)
  },
  async getDomainsForId (domainSetPlanId) {
    return flujax.get(`/dsp/getDomainListForDomainSetPlanId?domainSetPlanId=${domainSetPlanId}`)
  },
  async getEnums (enumSet) {
    return flujax.get(`/dsp/${enumSet}`)
  },
  async fetchSeeds (accountPlanId) {
    if (!accountPlanId) {
      return
    }
    return flujax.get(`/dsp/${accountPlanId}/seeds`)
  }
})
