<template>
  <t-accordion v-model:value="expanded" :light="light" :lazy="lazy">
    <p-accordion-panel value="1" pt:root:class="border-0">
      <p-accordion-header v-if="headerText || $slots.hasOwnProperty('header')">
        <component :is="headerTag"
                   class="d-flex-center w-100"
                   :class="{'text-danger': error, 'text-light': light}">
          <fluency-icon v-if="headerIcon"
                        :type="headerIcon"
                        :size="headerIconSize"
                        class="mr-3 mt-1"
                        :class="{
                        'text-danger': error,
                        ...iconClass
                      }"/>
          <slot name="header-end">
            <b-badge v-if="badgeText"
                     :variant="badgeVariant"
                     class="mr-3"
                     v-p-tooltip.hover.top="badgeTooltip">{{badgeText}}</b-badge>
          </slot>

          <slot name="header">
            <span class="overflow-hidden text-overflow-ellipsis" v-p-tooltip.top="headerTooltip">
              <span :class="headerClasses">{{headerText}}</span>

            <fluency-icon v-if="warning"
                          type="warning"
                          size="sm"
                          class="ml-2 mt-1 text-warning"/>
            </span>
          </slot>
          <div v-if="headerLine" class="flex-grow-1 border-bottom mx-4"></div>

        </component>
        <slot name="post-header" />
      </p-accordion-header>
      <p-accordion-content :pt:content:class="bodyClasses">
        <slot name="default" :close="close" />
        <div v-if="$slots.hasOwnProperty('footer')">
          <slot name="footer" />
        </div>
      </p-accordion-content>
    </p-accordion-panel>
  </t-accordion>
</template>

<script>

export default {
  name: 'tastyCollapse',
  props: {
    headerText: {
      type: String,
      default: ''
    },
    headerClasses: {
      type: String,
      default: ''
    },
    headerIcon: {
      type: String,
      default: null
    },
    headerIconSize: {
      type: String,
      default: 'md'
    },
    headerTooltip: {
      type: String,
      default: null
    },
    headerTag: {
      type: String,
      default: 'h4'
    },
    visible: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    badgeText: {
      type: [String, Number],
      default: null
    },
    badgeVariant: {
      type: String,
      default: 'secondary'
    },
    badgeTooltip: {
      type: String
    },
    iconClass: {
      type: [Array, Object, String],
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    },
    headerLine: {
      type: Boolean,
      default: true
    },
    bodyClasses: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    expanded: {
      get () {
        return this.visible ? '1' : '0'
      },
      set (value) {
        if (value === '1') {
          this.$emit('update:visible', true)
          this.$emit('show')
          this.$emit('shown')
        } else {
          this.$emit('update:visible', false)
          this.$emit('hide')
          this.$emit('hidden')
        }
      }
    }
  },
  methods: {
    close () {
      this.expanded = '0'
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-header {
  &:before {
    left: 5px;
    top: -2px;
  }
}
</style>
