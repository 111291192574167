export const Budget = (flujax) => ({
  reallocationAccountCompare ({ accountPlanId, budgetReallocationStrategyPlan }) {
    return flujax.post(`/budget/reallocation/account/compare?accountPlanId=${accountPlanId}`, budgetReallocationStrategyPlan)
  },
  reallocationAccountGenerate (accountPlan) {
    return flujax.post('/budget/reallocation/account/generate/', accountPlan)
  },
  reallocationAccountPreview ({ accountPlanId, budgetReallocationStrategyPlan }) {
    return flujax.post(`/budget/reallocation/account/preview?accountPlanId=${accountPlanId}`, budgetReallocationStrategyPlan)
  },
  reallocationCustomer () {
    return flujax.post('/budget/reallocation/customer/get')
  },
  reallocationAccountGet (accountPlan) {
    return flujax.post('/budget/reallocation/account/get', accountPlan)
  },
  async calculateMonthlyBudget (params) {
    const paramString = params.join('&')
    return flujax.post(`/budget/calculateMonthlyBudget?${paramString}`)
  },
  async getGroups (segmentId) {
    let path = '/budget/group/get'
    if (typeof segmentId === 'number') {
      path += `?segmentId=${segmentId}`
    }
    return flujax.post(path)
  },
  async campaignGroupBudgets (accountPlanId) {
    return flujax.post('/budget/getGroupBudgets', { accountPlanId })
  },
  async recomputeCampaignGroupBudgets (payload) {
    return flujax.post('/budget/recomputeGroupBudgets', payload)
  },
  async getBulkBudgetWorksheetByAccountPlans (accountPlans) {
    return flujax.post('/budget/getBulkBudgetWorksheetByAccountPlans', accountPlans)
  },
  async get ({ accountPlanId }, params = '') {
    return flujax.fetch.post(`/budget/getDetail${params}`, { accountPlanId })
  },
  async getDetail ({ budgetPlanId }, params = '') {
    return flujax.fetch.post(`/budget/getDetailForBudgetPlan${params}`, { budgetPlanId })
  },
  async previewPlan (budgetPlan) {
    return flujax.fetch.post('/budget/previewBudgetPlan', budgetPlan)
  },
  async customSpendPlan (budgetPlan) {
    return flujax.fetch.post('/budget/previewCustomBudgetPlan', budgetPlan)
  },
  async getBulkAdjustments ({ accountPlanId, totalAdjustment, fulfillmentStrategy, segmentId }) {
    if (accountPlanId) {
      const params = [`accountPlanId=${accountPlanId}`]
      if (totalAdjustment > 0) {
        params.push(`totalAdjustment=${totalAdjustment}`)
      }
      if (fulfillmentStrategy?.length > 0) {
        params.push(`fulfillmentStrategy=${fulfillmentStrategy}`)
      }
      const query = params.join('&')
      return flujax.fetch.post(`/budget/getAdjustmentsByBudget?${query}`)
    }

    const params = []
    if (typeof segmentId === 'number') {
      params.push(`segmentId=${segmentId}`)
    }
    const query = params.join('&')
    return flujax.fetch.post(`/budget/getAdjustmentsByAccount?${query}`)
  },
  async getFulfillmentStrategies () {
    return flujax.fetch.post('/budget/getFulfillmentStrategies')
  },
  async setReallocation (budgetReallocationStrategyPlan) {
    return flujax.post('/budget/reallocation/set', budgetReallocationStrategyPlan)
  },
  async accountsByBudgetGroup (data) {
    return flujax.post('/budget/group/convert', data)
  },
  async applyGroupBudgets (payload) {
    return flujax.post('/budget/applyGroupBudgets', payload)
  },
  async overrideOptimizedAmount (budgetPlan, partnerId, optimizedAmount) {
    return flujax.set.post(`/budget/overrideOptimizedAmount?partnerId=${partnerId}&optimizedAmount=${optimizedAmount}`, budgetPlan)
  },
  async applyBulkBudgetWorksheetByBulkBudgetWorksheet (payload) {
    return flujax.set.post('/budget/applyBulkBudgetWorksheetByBulkBudgetWorksheet', payload)
  },
  async clearPending (payload) {
    return flujax.set.post('/budget/clearPending', payload)
  },
  async reallocationApply (payload) {
    return flujax.set.post('/budget/reallocation/apply', payload)
  },
  async reallocationReject (payload) {
    return flujax.set.post('/budget/reallocation/reject', payload)
  },
  async bulkBudgetAdjustments ({ adjustments, fulfillmentStrategy }) {
    const query = fulfillmentStrategy?.length > 0 ? `?fulfillmentStrategy=${fulfillmentStrategy}` : ''
    return flujax.set.post(`/budget/setAdjustments${query}`, adjustments)
  },
  async getAdjustmentsByAccountDownload () {
    const data = {}
    const config = { responseType: 'blob', returnFullResponse: true }
    return flujax.fetch.get('/budget/getAdjustmentsByAccountDownload', data, config)
  }
})
