<template>
  <span v-if="type" :class="combinedClasses"
        v-html="svgMode ? (rawSvg || null) : null"></span>
</template>

<script>

const iconMap = {
  // iconMap key should match the type value.

  // BIONIC SANDBOX

  alert: {
    type: 'bionic',
    classes: ['alert'],
    file: 'alert'
  },
  layoutRight: {
    type: 'bionic',
    classes: ['layout-right'],
    file: 'layout-right'
  },
  blueprint: {
    type: 'bionic',
    classes: ['blueprint'],
    file: 'rocket'
  },
  blueprints: {
    type: 'bionic',
    classes: ['blueprints'],
    file: 'rocket'
  },
  publish: {
    type: 'bionic',
    classes: ['publish'],
    file: 'broadcast'
  },
  add: {
    type: 'bionic',
    classes: ['add'],
    file: 'plus'
  },
  apply: {
    type: 'bionic',
    classes: ['apply'],
    file: 'check'
  },
  analyze: {
    type: 'bionic',
    classes: ['analyze'],
    file: 'analyze'
  },
  announce: {
    type: 'bionic',
    classes: ['announce'],
    file: 'announcements'
  },
  beaker: {
    type: 'bionic',
    classes: ['beaker'],
    file: 'beaker'
  },
  bulk: {
    type: 'bionic',
    classes: ['bulk'],
    file: 'bulk'
  },
  calculator: {
    type: 'bionic',
    classes: ['calculator'],
    file: 'calculator'
  },
  calendar: {
    type: 'bionic',
    classes: ['calendar'],
    file: 'calendar'
  },
  find: {
    type: 'bionic',
    classes: ['find'],
    file: 'magnifying-glass'
  },
  graph: {
    type: 'bionic',
    classes: ['graph'],
    file: 'chart-bar'
  },
  checkmark: {
    type: 'bionic',
    classes: ['checkmark'],
    file: 'check'
  },
  clock: {
    type: 'bionic',
    classes: ['clock'],
    file: 'clock'
  },
  cloud: {
    type: 'bionic',
    classes: ['cloud'],
    file: 'cloud'
  },
  close: {
    type: 'bionic',
    classes: ['close'],
    file: 'close'
  },
  contrast: {
    type: 'bionic',
    classes: ['contrast'],
    file: 'contrast'
  },
  x: {
    type: 'bionic',
    classes: ['close', 'x'],
    file: 'close'
  },
  columns: {
    type: 'bionic',
    classes: ['columns'],
    file: 'columns'
  },
  copy: {
    type: 'bionic',
    classes: ['copy'],
    file: 'copy'
  },
  crop: {
    type: 'bionic',
    classes: ['crop'],
    file: 'crop'
  },
  cut: {
    type: 'bionic',
    classes: ['cut'],
    file: 'cut'
  },
  dashboard: {
    type: 'bionic',
    classes: ['dashboard'],
    file: 'dashboard'
  },
  database: {
    type: 'bionic',
    classes: ['database'],
    file: 'database'
  },
  delete: {
    type: 'bionic',
    classes: ['delete'],
    file: 'trash'
  },
  ellipses: {
    type: 'bionic',
    classes: ['ellipses'],
    file: 'dots-vertical'
  },
  edit: {
    type: 'bionic',
    classes: ['edit'],
    file: 'edit'
  },
  envelopeClosed: {
    type: 'bionic',
    classes: ['envelope-closed'],
    file: 'send'
  },
  send: {
    type: 'bionic',
    classes: ['send'],
    file: 'send-alt'
  },
  filter: {
    type: 'bionic',
    classes: ['filter'],
    file: 'filter'
  },
  flag: {
    type: 'bionic',
    classes: ['flag'],
    file: 'flag'
  },
  help: {
    type: 'bionic',
    classes: ['help'],
    file: 'help'
  },
  helpChat: {
    type: 'bionic',
    classes: ['help'],
    file: 'help'
  },
  mapMarker: {
    type: 'bionic',
    classes: ['map-marker'],
    file: 'geo'
  },
  globe: {
    type: 'bionic',
    classes: ['globe'],
    file: 'global'
  },
  history: {
    type: 'bionic',
    classes: ['history'],
    file: 'history'
  },
  // consolidate all to share start
  share: {
    type: 'bionic',
    classes: ['share'],
    file: 'share'
  },
  sharedBudget: {
    type: 'bionic',
    classes: ['shared-budget'],
    file: 'shared-budget'
  },
  linkExternal: {
    type: 'bionic',
    classes: ['link-external'],
    file: 'share'
  },
  linkOut: {
    type: 'bionic',
    classes: ['link-out'],
    file: 'share'
  },
  linkOver: {
    type: 'bionic',
    classes: ['link-over'],
    file: 'share'
  },
  // consolidate all to share end and image start
  imagePortrait: {
    type: 'bionic',
    classes: ['image', 'image-portrait'],
    file: 'image'
  },
  imageLandscape: {
    type: 'bionic',
    classes: ['image', 'image-landscape'],
    file: 'image'
  },
  // consolidate image end
  emptyStar: {
    type: 'bionic',
    classes: ['star-empty'],
    file: 'star-unfilled'
  },
  star: {
    type: 'bionic',
    classes: ['star-filled'],
    file: 'star-filled'
  },
  learningMode: {
    type: 'bionic',
    classes: ['learning-mode'],
    file: 'learning-mode'
  },
  learningLimited: {
    type: 'bionic',
    classes: ['learning-mode-limited'],
    file: 'learning-mode-limited'
  },
  link: {
    type: 'bionic',
    classes: ['link'],
    file: 'link'
  },
  linkBroken: {
    type: 'bionic',
    classes: ['link-broken'],
    file: 'link-unlink'
  },
  // consolidate lists
  list: {
    type: 'bionic',
    classes: ['list'],
    file: 'list'
  },
  listNested: {
    type: 'bionic',
    classes: ['list-nested'],
    file: 'list'
  },
  listRich: {
    type: 'bionic',
    classes: ['list-rich'],
    file: 'list'
  },
  // consolidate lists end
  lock: {
    type: 'bionic',
    classes: ['lock-locked'],
    file: 'lock-locked'
  },
  unlock: {
    type: 'bionic',
    classes: ['lock-unlocked'],
    file: 'lock-unlocked'
  },
  folder: {
    type: 'bionic',
    classes: ['folder'],
    file: 'campaigns'
  },
  fullscreenEnter: {
    type: 'bionic',
    classes: ['maximize'],
    file: 'maximize'
  },
  fullscreenExit: {
    type: 'bionic',
    classes: ['minimize'],
    file: 'minimize'
  },
  openModal: {
    type: 'bionic',
    classes: ['open-modal'],
    file: 'open-modal'
  },
  slashCommand: {
    type: 'bionic',
    classes: ['slash-command'],
    file: 'slash-command'
  },
  slashCommandIcon: {
    type: 'bionic',
    classes: ['slash-command-icon'],
    file: 'slash-command-icon'
  },
  measurement: {
    type: 'bionic',
    classes: ['measurement'],
    file: 'measurement'
  },
  moreInfo: {
    type: 'bionic',
    classes: ['more-info'],
    file: 'help'
  },
  info: {
    type: 'bionic',
    classes: ['info-circle'],
    file: 'info-circle'
  },
  move: {
    type: 'bionic',
    classes: ['move'],
    file: 'move'
  },
  menuSelected: {
    type: 'bionic',
    classes: ['menu-selected'],
    file: 'history'
  },
  qa: {
    type: 'bionic',
    classes: ['qa'],
    file: 'history'
  },

  muse: {
    type: 'bionic',
    classes: ['muse'],
    file: 'muse'
  },
  notes: {
    type: 'bionic',
    classes: ['notes'],
    file: 'notes'
  },
  bell: {
    type: 'bionic',
    classes: ['bell'],
    file: 'bell'
  },
  mute: {
    type: 'bionic',
    classes: ['mute'],
    file: 'mute'
  },
  pause: {
    type: 'bionic',
    classes: ['pause'],
    file: 'media-pause'
  },
  performance: {
    type: 'bionic',
    classes: ['performance'],
    file: 'performance'
  },
  'performance-max': {
    type: 'bionic',
    classes: ['performance-max'],
    file: 'performance-max'
  },
  performanceMax: {
    type: 'bionic',
    classes: ['performance-max'],
    file: 'performance-max'
  },
  pin: {
    type: 'bionic',
    classes: ['pin'],
    file: 'pin'
  },
  // play: {
  //   type: 'bionic',
  //   classes: ['media-play-sm'],
  //   file: 'media-play-sm'
  // },
  mediaPlay: {
    type: 'bionic',
    classes: ['media-play'],
    file: 'media-play'
  },
  promotePost: {
    type: 'bionic',
    classes: ['promote-post'],
    file: 'promote-post'
  },
  // condense refresh and reload
  refresh: {
    type: 'bionic',
    classes: ['refresh'],
    file: 'reload'
  },
  reload: {
    type: 'bionic',
    classes: ['reload'],
    file: 'reload'
  },
  document: {
    type: 'bionic',
    classes: ['document'],
    file: 'report'
  },
  hide: {
    type: 'bionic',
    classes: ['hide'],
    file: 'hide'
  },
  preview: {
    type: 'bionic',
    classes: ['preview'],
    file: 'preview'
  },
  review: {
    type: 'bionic',
    classes: ['review'],
    file: 'review'
  },
  save: {
    type: 'bionic',
    classes: ['save'],
    file: 'save'
  },
  search: {
    type: 'bionic',
    classes: ['search'],
    file: 'magnifying-glass'
  },
  settings: {
    type: 'bionic',
    classes: ['settings'],
    file: 'settings'
  },
  blueprintSnippet: {
    type: 'bionic',
    classes: ['blueprint-snippet'],
    file: 'snippet'
  },
  stop: {
    type: 'bionic',
    classes: ['stop'],
    file: 'media-stop-filled'
  },
  tag: {
    type: 'bionic',
    classes: ['tag'],
    file: 'tag'
  },
  tags: {
    type: 'bionic',
    classes: ['tags'],
    file: 'tags'
  },
  target: {
    type: 'bionic',
    classes: ['target'],
    file: 'target'
  },
  toggle: {
    type: 'bionic',
    classes: ['toggle'],
    file: 'toggle'
  },
  toolbox: {
    type: 'bionic',
    classes: ['toolbox'],
    file: 'tools'
  },
  trailguide: {
    type: 'bionic',
    classes: ['trailguide'],
    file: 'trailguide'
  },
  // consolidate person/user
  person: {
    type: 'bionic',
    classes: ['person'],
    file: 'user'
  },
  user: {
    type: 'bionic',
    classes: ['user'],
    file: 'user'
  },
  userManagement: {
    type: 'bionic',
    classes: ['user-management'],
    file: 'user-management'
  },
  userCircle: {
    type: 'bionic',
    classes: ['user-circle'],
    file: 'user'
  },
  warning: {
    type: 'bionic',
    classes: ['warning'],
    file: 'warning'
  },
  integrations: {
    type: 'bionic',
    classes: ['integrations'],
    file: 'integrations'
  },
  // arrows
  import: {
    type: 'bionic',
    classes: ['upload'],
    file: 'upload'
  },
  upload: {
    type: 'bionic',
    classes: ['upload'],
    file: 'upload'
  },
  download: {
    type: 'bionic',
    classes: ['download'],
    file: 'download'
  },
  skip: {
    type: 'bionic',
    classes: ['arrow-continue'],
    file: 'arrow-continue'
  },
  collapseRight: {
    type: 'bionic',
    classes: ['collapse-right'],
    file: 'arrow-right'
  },
  collapseLeft: {
    type: 'bionic',
    classes: ['collapse-left'],
    file: 'arrow-left'
  },
  // consolidate these two:
  embeddableBlueprint: {
    type: 'bionic',
    classes: ['embeddable-blueprint'],
    file: 'snippet'
  },
  puzzlePiece: {
    type: 'bionic',
    classes: ['puzzle-piece'],
    file: 'snippet'
  },
  // CHANNELS
  display: {
    type: 'bionic',
    classes: ['display'],
    file: 'display'
  },
  facebook: {
    type: 'bionic',
    classes: ['social'],
    file: 'social'
  },
  // search: { classes: ['magnifying-glass'] },
  shopping: {
    type: 'bionic',
    classes: ['shopping'],
    file: 'shopping'
  },
  accountManagement: {
    type: 'bionic',
    classes: ['account-management'],
    file: 'accounts-management'
  },
  account: {
    type: 'bionic',
    classes: ['account'],
    file: 'accounts'
  },
  ad: {
    type: 'bionic',
    classes: ['ad'],
    file: 'creative'
  },
  ads: {
    type: 'bionic',
    classes: ['ads'],
    file: 'creative'
  },
  adgroup: {
    type: 'bionic',
    classes: ['ad-group'],
    file: 'ad-groups'
  },
  adGroup: {
    type: 'bionic',
    classes: ['ad-group'],
    file: 'ad-groups'
  },
  adgroups: {
    type: 'bionic',
    classes: ['ad-groups'],
    file: 'ad-groups'
  },
  audiences: {
    type: 'bionic',
    classes: ['audiences'],
    file: 'audience'
  },
  // remove this one in ui
  code: { classes: ['code'] },
  campaign: {
    type: 'bionic',
    classes: ['campaign'],
    file: 'campaigns'
  },
  campaigns: {
    type: 'bionic',
    classes: ['campaigns'],
    file: 'campaigns'
  },
  keyword: {
    type: 'bionic',
    classes: ['keyword'],
    file: 'keywords'
  },
  keywords: {
    type: 'bionic',
    classes: ['keywords'],
    file: 'keywords'
  },
  key: {
    type: 'bionic',
    classes: ['key'],
    file: 'keywords'
  },
  budget: {
    type: 'bionic',
    classes: ['budgets'],
    file: 'budgets'
  },
  budgets: {
    type: 'bionic',
    classes: ['budgets'],
    file: 'budgets'
  },
  extension: {
    type: 'bionic',
    classes: ['extension'],
    file: 'extension'
  },
  creative: {
    type: 'bionic',
    classes: ['creative'],
    file: 'creative'
  },
  creatives: {
    type: 'bionic',
    classes: ['creatives'],
    file: 'creative'
  },
  form: {
    type: 'bionic',
    classes: ['form'],
    file: 'form'
  },
  criterion: {
    type: 'bionic',
    classes: ['criterion'],
    file: 'target'
  },
  launch: {
    type: 'bionic',
    classes: ['launch'],
    file: 'rocket'
  },
  fluency: {
    type: 'bionic',
    classes: ['fluency', 'brand-logo'],
    file: 'fluency-mark'
  },
  fluencyColor: {
    type: 'fluency',
    classes: ['fluency', 'brand-logo', 'fluency-color', 'brand-logo-color'],
    file: 'fluency'
  },
  fluencyColorLight: {
    type: 'fluency',
    classes: ['fluency', 'brand-logo', 'fluency-color', 'brand-logo-color', 'brand-logo-color--light'],
    file: 'fluency'
  },
  duplicate: {
    type: 'bionic',
    classes: ['duplicate'],
    file: 'copy'
  },
  wand: {
    type: 'bionic',
    classes: ['magic-wand'],
    file: 'magic-wand'
  },
  spreadsheet: {
    type: 'bionic',
    classes: ['spreadsheet'],
    file: 'table'
  },
  speechBubble: {
    type: 'bionic',
    classes: ['speech-bubble'],
    file: 'speech-bubble'
  },
  menu: {
    type: 'bionic',
    classes: ['menu'],
    file: 'menu'
  },

  // FLUENCY SPECIFIC

  // crossroads: {
  //   type: 'fluency',
  //   classes: ['crossroads'],
  //   file: 'crossroads'
  // },

  toDoList: {
    type: 'fluency',
    classes: ['to-do-list'],
    file: 'to-do-list'
  },
  workshop: {
    type: 'fluency',
    classes: ['workshop'],
    file: 'workshop'
  },
  sliders: {
    type: 'fluency',
    classes: ['sliders'],
    file: 'sliders'
  },
  takeABreak: {
    type: 'fluency',
    classes: ['take-a-break'],
    file: 'take-a-break'
  },
  filterUp: {
    type: 'fluency',
    classes: ['filter-up'],
    file: 'filter-up'
  },
  filterDown: {
    type: 'fluency',
    classes: ['filter-down'],
    file: 'filter-down'
  },
  scrollToTop: {
    type: 'fluency',
    classes: ['scroll-to-top'],
    file: 'close-up'
  },

  unmute: {
    type: 'fluency',
    classes: ['unmute'],
    file: 'unmute'
  },
  sadRobot: {
    type: 'fluency',
    classes: ['sad-robot'],
    file: 'sad-robot'
  },
  verticalAgriculture: {
    type: 'fluency',
    classes: ['vertical-agriculture'],
    file: 'verticals/agriculture'
  },
  verticalAutomotive: {
    type: 'fluency',
    classes: ['vertical-automotive'],
    file: 'verticals/automotive'
  },
  verticalEvents: {
    type: 'fluency',
    classes: ['vertical-events'],
    file: 'verticals/events'
  },
  verticalFlooring: {
    type: 'fluency',
    classes: ['vertical-flooring'],
    file: 'verticals/flooring'
  },
  verticalHomeServices: {
    type: 'fluency',
    classes: ['vertical-home-services'],
    file: 'verticals/home-services'
  },
  verticalMultiFamilyHousing: {
    type: 'fluency',
    classes: ['vertical-multi-family-housing'],
    file: 'verticals/multi-family-housing'
  },
  verticalPowersports: {
    type: 'fluency',
    classes: ['vertical-power-sports'],
    file: 'verticals/power-sports'
  },
  verticalRealEstate: {
    type: 'fluency',
    classes: ['vertical-real-estate'],
    file: 'verticals/real-estate'
  },
  layoutHorizontal: {
    type: 'fluency',
    classes: ['layout-horizontal layout-horizontal-thin'],
    file: 'layout-horizontal-thin'
  },
  layoutHorizontalThick: {
    type: 'fluency',
    classes: ['layout-horizontal layout-horizontal-thick'],
    file: 'layout-horizontal-thick'
  },
  layoutVertical: {
    type: 'fluency',
    classes: ['layout-vertical layout-vertical-thin'],
    file: 'layout-vertical-thin'
  },
  layoutVerticalThick: {
    type: 'fluency',
    classes: ['layout-vertical layout-vertical-thick'],
    file: 'layout-vertical-thick'
  },
  exclamation: {
    type: 'fluency',
    classes: ['exclamation'],
    file: 'exclamation'
  },
  unsyncedShare: {
    type: 'fluency',
    classes: ['unsyncedShare'],
    file: 'shared-unsynced'
  },
  synced: {
    type: 'fluency',
    classes: ['synced'],
    file: 'synced'
  },
  unsynced: {
    type: 'fluency',
    classes: ['unsynced'],
    file: 'unsynced'
  },
  party: {
    type: 'fluency',
    classes: ['party'],
    file: 'party'
  },
  partyDots: {
    type: 'fluency',
    classes: ['party-dots'],
    file: 'party-dots'
  },
  google: {
    type: 'fluency',
    classes: ['google'],
    file: 'google-reporting'
  },
  manage: {
    type: 'fluency',
    classes: ['manage'],
    file: 'manage'
  },
  robot: {
    type: 'fluency',
    classes: ['robot'],
    file: 'my-blueprints'
  },
  systemActivity: {
    type: 'fluency',
    classes: ['system-activity'],
    file: 'system-activity'
  },
  aiComputer: {
    type: 'fluency',
    classes: ['ai-computer'],
    file: 'ai-computer'
  },

  // ACTIONS
  // layoutRight: { classes: ['layout-right'] },
  addInBadge: { classes: ['circle-x rotate-clockwise-45'] },
  alignLeft: { classes: ['align-left'] },
  alignCenter: { classes: ['align-center'] },
  alignRight: { classes: ['align-right'] },
  archive: { classes: ['bullhorn'] },
  ban: { classes: ['ban'], type: 'bionic', file: 'ban' },
  cancel: { classes: ['action-undo'] },
  clear: { classes: ['circle-x'] },
  circleCheck: { classes: ['circle-check'] },
  collapse: { type: 'fluency', classes: ['chevron-top'], file: 'chevron-top' },
  expand: { type: 'fluency', classes: ['chevron-bottom'], file: 'chevron-bottom' },
  iphone: { classes: ['iphone'] },
  login: { classes: ['account-login'] },
  logout: { classes: ['account-logout'] },
  record: { classes: ['media-record'] },
  show: { classes: ['eye-open'] },
  random: { classes: ['random'] },
  question: { classes: ['question-mark'] },
  quote: { classes: ['double-quote-serif-right'] },
  quoteLeft: { classes: ['double-quote-serif-left'] },
  redo: { classes: ['action-redo'] },
  return: { classes: ['carriage-return'] },
  subtract: { classes: ['minus'] },
  undo: { classes: ['action-undo'] },
  zoomIn: { classes: ['zoom-in'] },
  zoomOut: { classes: ['zoom-out'] },

  // ELEMENTS
  play: { classes: ['media-play'] },
  book: { classes: ['book'] },
  bug: { classes: ['bug'] },
  clipboard: { classes: ['clipboard'] },
  compass: { classes: ['compass'] },
  dial: { classes: ['dial'] },
  fire: { classes: ['fire'] },
  fork: { classes: ['fork'] },
  group: { classes: ['group'] },
  heart: { classes: ['heart'] },
  home: { classes: ['home'] },
  internalLink: { classes: ['account-login'] },
  lightningBolt: { classes: ['electric'] },
  lightningBolt2: { classes: ['lightning-bolt'] },
  loop: { classes: ['loop-circular sm'] },
  map: { classes: ['map'] },
  pdf: { classes: ['file-pdf'] },
  price: { classes: ['dollar'] },
  rocket: { type: 'bionic', classes: ['rocket'], file: 'rocket' },

  rss: { classes: ['rss'] },
  switch: { type: 'bionic', classes: ['switch'], file: 'switch' },
  server: { classes: ['server'] },
  suggestion: { classes: ['lightbulb-alt-on'] },
  text: { classes: ['text'] },
  thumbDown: { type: 'bionic', classes: ['thumb-down'], file: 'thumbs-up' },
  thumbUp: { type: 'bionic', classes: ['thumb-up'], file: 'thumbs-down' },
  transfer: { classes: ['transfer'] },
  updog: { classes: ['thumb-up'] },
  video: { classes: ['video'] },
  sound: { classes: ['volume-high'] },
  gridSmall: { classes: ['grid-two-up'] },
  gridMedium: { classes: ['grid-three-up'] },

  // SHAPES,
  arrowUp: { classes: ['arrow-top-line'] },
  arrowRight: { classes: ['arrow-right-line'] },
  arrowDown: { classes: ['arrow-bottom-line'] },
  arrowLeft: { classes: ['arrow-left-line'] },

  caretUp: { classes: ['caret-top'], file: 'caret-top' },
  caretTop: { classes: ['caret-top'], file: 'caret-top' },
  caretRight: { classes: ['caret-right'], file: 'caret-right' },
  caretDown: { classes: ['caret-bottom'], file: 'caret-bottom' },
  caretBottom: { classes: ['caret-bottom'], file: 'caret-bottom' },
  caretLeft: { classes: ['caret-left'], file: 'caret-left' },

  chevronUp: { type: 'bionic', classes: ['chevron-up'], file: 'chevron-up' },
  chevronTop: { type: 'bionic', classes: ['chevron-top'], file: 'chevron-up' },
  chevronRight: { type: 'bionic', classes: ['chevron-right'], file: 'chevron-right' },
  chevronDown: { type: 'bionic', classes: ['chevron-bottom'], file: 'chevron-bottom' },
  chevronBottom: { type: 'bionic', classes: ['chevron-bottom'], file: 'chevron-bottom' },
  chevronLeft: { type: 'bionic', classes: ['chevron-left'], file: 'chevron-left' }
}

const appIcons = import.meta.glob(['/assets/icons/**/*.svg'], { query: '?raw', import: 'default' })
const bionicIcons = import.meta.glob('../../assets/icons/bionic/**/*.svg', { query: '?raw', import: 'default' })
const coreIcons = import.meta.glob('../../assets/icons/**/*.svg', { query: '?raw', import: 'default' })

export default {
  name: 'fluencyIcon',
  props: {
    type: String,
    size: String
  },
  data () {
    return {
      rawSvg: ''
    }
  },
  watch: {
    type: {
      immediate: true,
      handler: async function (val) {
        if (val && this.svgMode) {
          await this.getSvg(val)
        }
      }
    }
  },
  computed: {
    lib () {
      return iconMap[this.type] && iconMap[this.type]?.type ? iconMap[this.type].type : 'iconic'
    },
    classes () {
      const type = this.type
      const lib = this.lib
      if (iconMap[type]) {
        let classStr = ''
        if (Array.isArray(iconMap[type].classes)) {
          iconMap[type].classes.forEach(cl => {
            classStr += `${lib}-${cl} `
          })
        }
        classStr = classStr.replace(/ +(?= )/g, '')
        classStr = classStr.trim()
        return `${lib} ${classStr}`
      } else {
        if (type.startsWith('vertical')) {
          return 'iconic iconic-list' // fallback for `Verticals` that are not represented by an icon
        }
        const str = `FluencyIcon component tried to render unmapped icon of type: ${type}`
        console.warn(str)
        return `iconic iconic-shape-hexagon icon-failure-${type}`
      }
    },
    combinedClasses () {
      const sizeClassObj = {
        'icon-lg': this.size === 'lg',
        'icon-md': this.size === 'md',
        'icon-sm': this.size === 'sm'
      }
      const classes = [sizeClassObj, this.classes, this.$attrs.class]
      if (this.svgMode) {
        classes.push('fluency-svg-icon')
      } else {
        classes.push('fluency-icon')
      }
      return classes
    },
    svgMode () {
      return ['fluency', 'bionic'].includes(this.lib) && iconMap[this.type]?.file
    }
  },
  methods: {
    onClick () {
      return this.$attrs.onClick || (() => {})
    },
    async getSvg () {
      const name = iconMap[this.type].file
      const appPath = `/assets/icons/${name}.svg`
      const bionicPath = `../../assets/icons/bionic/${name}.svg`
      const corePath = `../../assets/icons/${name}.svg`

      if (bionicIcons[bionicPath]) {
        const mod = await bionicIcons[bionicPath]()
        this.rawSvg = mod
      } else if (appIcons[appPath]) {
        const mod = await appIcons[appPath]()
        this.rawSvg = mod
      } else if (coreIcons[corePath]) {
        const mod = await coreIcons[corePath]()
        this.rawSvg = mod
      }
    }
  }
}

</script>

<style lang="scss">
  .iconic {
    top: 0;
  }
  .top-1 {
    top: 1px !important;
  }
  .fluency-icon {
    &.learning-mode {
      color: $fluency-blue;
      animation: rotate-clockwise 30s infinite linear; // rotating keyframes defined in custom.css
    }

    &.learning-limited {
      color: $warning;
    }
  }

  .fluency-svg-icon {
    &.embeddable-blueprint-icon {
      transform: scale(1.2) scaleY(1.3) translateY(-1px);
    }
  }

  // USE `class="static"` IN SVG `<path>` ELEMENTS TO RETAIN SPECIFIED COLORS.
  .fluency-svg-icon {
    position: relative;
    display: inline-block;
    min-width: 1.25em;
    top: -2px;

    &.icon-lg {
      min-width: 1.5em;
    }

    &.icon-md {
      //min-width: 1.0em;
    }

    &.icon-sm {
      min-width: 0.8em;
    }

    &.icon-xs {
      min-width: 0.6em;
    }
  }

  .inherit-font-color {
    color: inherit;
  }

  .fluency-brand-logo-color {
    .brand-name {
      fill: $fluency-gray;
    }

    &--light .brand-name {
      fill: #fff;
    }
  }

</style>
