<template>
  <div class="d-inline-block">
<!--    This is configured for a hover/focus trigger-->
    <span class="d-flex-center cursor-pointer"
          :class="[(variant) ? `text-${variant}` : '', iconClasses]"
          ref="tooltipTarget"
          v-popover-trigger.hover.focus="infoTooltipPopover"
          @click="!allowClickToBubble ? $event.stopPropagation() : null">
      <fluency-icon :type="icon" :size="iconSize" />
    </span>
    <p-popover ref="infoTooltipPopover"
               :base-z-index="1100"
               :append-to="container"
               :pt="{ root: { class: `${customPopoverClass}`}}">
      <slot name="title"><span v-html="title" /></slot>

    </p-popover>
  </div>
</template>
<script setup>
import { ref } from 'vue'

defineProps({
  title: String,
  icon: {
    type: String,
    default: 'info'
  },
  iconSize: {
    type: String,
    default: 'md'
  },
  iconClasses: {
    type: String
  },
  variant: {
    type: String
  },
  popover: { // use a popover style tooltip instead of a tooltip style
    type: Boolean,
    default: false
  },
  container: {
    type: [String, Object, HTMLElement]
  },
  customPopoverClass: {
    type: String,
    default: () => ''
  },
  allowClickToBubble: {
    type: Boolean,
    default: false
  }
})

const tooltipTarget = ref(null)
const infoTooltipPopover = ref(null)

</script>

<style lang='scss' scoped>

</style>
