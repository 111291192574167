// basis (17) supports CONTEXTUAL_TARGET and AUDIENCE for the targetType
// (can be lowercase)
// amazon (6) supports audience only
export const Tree = (flujax) => ({
  async search (partnerId, targetType, searchTerm, accountPlanId) {
    let url = `/tree/targeting/search?partnerId=${partnerId}&targetType=${targetType}`
    if (accountPlanId) {
      url += `&accountPlanId=${accountPlanId}`
    }
    return flujax.post(url, { term: searchTerm })
  },
  getNodeChildren (partnerId, targetType, nodeId, accountPlanId) {
    let url = `/tree/targeting/${nodeId}/children?partnerId=${partnerId}&targetType=${targetType}`
    if (accountPlanId) {
      url += `&accountPlanId=${accountPlanId}`
    }
    return flujax.get(url)
  },
  getRoots (partnerId, targetType, accountPlanId) {
    let url = `/tree/targeting/roots?partnerId=${partnerId}&targetType=${targetType}`
    if (accountPlanId) {
      url += `&accountPlanId=${accountPlanId}`
    }
    return flujax.get(url)
  }
})
