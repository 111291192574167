
export const convertButtonVariant = (variant) => {
  if (!variant) return
  switch (variant) {
    case 'primary':
      return 'primary'
    case 'secondary':
      return 'secondary'
    case 'success':
      return 'success'
    case 'warning':
      return 'warn'
    case 'danger':
      return 'danger'
    case 'info':
      return 'info'
    case 'light':
      return 'secondary'
    case 'dark':
      return 'contrast'
    default:
      console.error('Did not convert variant - ' + variant)
      return undefined
  }
}

export const convertSize = (bvSize) => {
  if (!bvSize) return
  switch (bvSize) {
    case 'sm':
      return 'small'
    case 'md':
      return ''
    case 'lg':
      return 'large'
    case 'small':
    case 'medium':
    case 'large':
      return bvSize
    default:
      console.error('Did not convert size ' + bvSize)
      return bvSize
  }
}
