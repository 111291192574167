<script setup>
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
  bodyBgVariant: {
    type: String,
    default: ''
  },
  bodyClass: {
    type: String,
    default: ''
  },
  cancelTitle: {
    type: String,
    default: ''
  },
  cancelVariant: {
    type: String,
    default: ''
  },
  centered: {
    type: Boolean,
    default: false
  },
  dialogClass: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  footerClass: {
    type: String,
    default: ''
  },
  headerClass: {
    type: String,
    default: ''
  },
  hideFooter: {
    type: Boolean,
    default: false
  },
  hideHeader: {
    type: Boolean,
    default: false
  },
  hideHeaderClose: {
    type: Boolean,
    default: false
  },
  modalClass: {
    type: String,
    default: ''
  },
  noCloseOnBackdrop: {
    type: Boolean,
    default: false
  },
  noCloseOnEsc: {
    type: Boolean,
    default: false
  },
  noFade: {
    type: Boolean,
    default: false
  },
  okDisabled: {
    type: Boolean,
    default: false
  },
  okOnly: {
    type: Boolean,
    default: false
  },
  okTitle: {
    type: String,
    default: ''
  },
  okVariant: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'med'
  },
  title: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: ''
  },
  titleTag: {
    type: String,
    default: 'h3'
  }
})

const rootComponentClasses = computed(() => {
  return [
    props.dialogClass,
    'flex-fill',
    'modal-dialog',
    'modal-' + props.size
  ]
})

const contentClasses = computed(() => {
  const classes = [
    props.bodyClass,
    props.bodyBgVariant
  ]

  if (['xxl', 'mostly-full'].includes(props.size)) {
    classes.push('flex-fill')
  }

  return classes
})

// Hide the close button when `hideHeaderClose=true` instead of mapping prop to `closable`.
// Mapping essentially forces `closeOnEscape=true` when `hideHeaderClose=true`
const closeButtonClasses = computed(() => {
  return props.hideHeaderClose ? ['d-none'] : []
})

const contentPaddingDt = computed(() => {
  return props.hideHeader
    ? '{overlay.modal.padding}'
    : '0 {overlay.modal.padding} {overlay.modal.padding} {overlay.modal.padding}'
})

const modalTransition = computed(() => {
  return props.noFade ? { name: 'none' } : null
})

const visible = defineModel({ default: false })

const dialogRef = ref(null)

onMounted(() => {
  dialogRef.value.close = close
})

const emit = defineEmits([
  'cancel',
  'change',
  'close',
  'hidden',
  'hide',
  'ok',
  'show',
  'shown',
  'update:modelValue'
])

function cancel (event) {
  emit('cancel', event)
  hide(event)
}

function cancelEvent (event) {
  if (event === undefined) {
    return false
  }

  return event.defaultPrevented || event.cancelBubble
}

function change (event) {
  emit('change', event)
  emit('update:modelValue', event)
  visible.value = event
}

function close (event) {
  emit('close', event)
  hide(event)
}

function ok (event) {
  emit('ok', event)
  hide(event)
}

function hide (event) {
  emit('hide', event)

  if (!cancelEvent(event)) {
    change(false)
  }
}

function show () {
  change(true)
}

defineExpose({
  cancel,
  close,
  hide,
  ok,
  show
})
</script>

<template>
  <p-modal v-model:visible="visible"
           @show="emit('show');emit('shown')"
           @after-hide="emit('hidden')"
           :closeOnEscape="!noCloseOnEsc"
           :dismissableMask="!noCloseOnBackdrop"
           :draggable="false"
           :showHeader="!hideHeader"
           :pt="{
              mask: {
                id,
                class: modalClass,
              },
              root: rootComponentClasses,
              header: headerClass,
              content: contentClasses,
              footer: ['border-top', footerClass],
              transition: modalTransition,
              pcCloseButton: {
                root: closeButtonClasses
              }
           }"
           :dt="{
              'header.padding': '1rem {overlay.modal.padding}',
              'content.padding': contentPaddingDt,
              'footer.padding': '1rem {overlay.modal.padding}'
           }"
           :position="centered ? 'center' : 'top'"
           ref="dialogRef"
           modal >

    <template #header>
      <slot name="modal-header" v-bind="{ close }">
        <slot name="modal-title">
          <component :is="titleTag" class="mb-0" :class="titleClass">{{title}}</component>
        </slot>
      </slot>
    </template>

    <slot></slot>

    <template v-if="!hideFooter" #footer>
      <slot name="modal-footer" v-bind="{ cancel, close, ok }">
        <p-button v-if="!okOnly"
                  @click="cancel($event)"
                  :label="cancelTitle || 'Cancel'"
                  :severity="cancelVariant || 'secondary'">
          <slot name="modal-cancel"></slot>
        </p-button>

        <p-button @click="ok($event)"
                  :label="okTitle || 'OK'"
                  :disabled="okDisabled"
                  :severity="okVariant || 'primary'" >
          <slot name="modal-ok"></slot>
        </p-button>
      </slot>
    </template>
  </p-modal>
</template>

<style>
.p-dialog-content.overflow-visible {
  overflow: visible;
}
</style>
