import { createStore } from 'vuex'
import baseActions from './actions'
import baseGetters from './getters'
import baseMutations from './mutations'

import bulkEdit from './modules/bulkEdit'
import colab from './modules/colab'
import createFlow from './modules/createFlow'
import forum from './modules/forum'
import mediaSelector from './modules/mediaSelector'
import notifications from './modules/notifications'
import table from './modules/table'

import { deepSpread } from 'core-ui/utils/deepSpread'

let settings = {}
let user = {}
let copyBin = {}
let skeletons = {}
let enumerations = {}
let reportDefinitions = []
let partners = []
let verticals = []
let advertisingChannels = []
let customer = {}
let customerState = {}
let timeZones = []
let actingAsUser
let userSavedSegments = []
let savedSegmentFields = []

export const strict = false

if (localStorage) {
  try {
    settings = JSON.parse(localStorage.getItem('backpack.app.settings') || '{}') || {}
    user = JSON.parse(localStorage.getItem('backpack.user') || '{}')
    copyBin = JSON.parse(localStorage.getItem('copiedPlans') || '{}')
    skeletons = JSON.parse(localStorage.getItem('skeletons') || '{}')
    enumerations = JSON.parse(localStorage.getItem('enumerations') || '{}')
    reportDefinitions = JSON.parse(localStorage.getItem('reportDefinitions') || '[]')
    partners = JSON.parse(localStorage.getItem('partners') || '[]')
    verticals = JSON.parse(localStorage.getItem('verticals') || '[]')
    actingAsUser = localStorage.getItem('actingAsUser') || ''
    actingAsUser = actingAsUser && !isNaN(actingAsUser) ? Number.parseInt(actingAsUser) : ''
    advertisingChannels = JSON.parse(localStorage.getItem('advertisingChannels') || '[]')
    customer = JSON.parse(localStorage.getItem('customer') || '{}')
    customerState = JSON.parse(localStorage.getItem('customerState') || '{}')
    timeZones = JSON.parse(localStorage.getItem('timeZones') || '[]')
    userSavedSegments = JSON.parse(localStorage.getItem('userSavedSegments') || '[]')
    savedSegmentFields = JSON.parse(localStorage.getItem('savedSegmentFields') || '[]')
  } catch (e) {
    console.log(e)
  }
}

// == settings to force clear on load ==========
settings.channelGroupsFilter = []

// == default splitpanes object
const defaultSettings = {
  splitpanes: {
    blueprintEditor: {
      A: 20,
      B: 60,
      C: 20,
      A1: 50,
      A2: 50
    },
    blueprintEditor2: {
      nav: 20,
      main: 60,
      tags: 20
    },
    manageDetails: {
      nav: 15,
      table: 85
    },
    bulkEdit: {
      A: 5,
      B: 5,
      C: 90
    },
    bulkReceipt: {
      A: 33,
      B: 67
    },
    manage: {
      nav: 15,
      mainTop: 50,
      mainBottom: 50
    },
    alertsWorkshop: {
      A: 25,
      B: 50,
      C: 25
    },
    userFeedback: {
      A: 25,
      B: 75
    }
  }
}

settings.splitpanes = { ...defaultSettings.splitpanes, ...settings.splitpanes }

const initialState = {
  user,
  okta: {
    accessToken: undefined,
    userId: undefined,
    idToken: undefined
  },
  app: {
    nodeEnv: process.env.NODE_ENV || 'unknown',
    init: false,
    unsavedTools: [],
    scrolledPastHeader: false,
    headerHeight: 0,
    createSingle: '',
    trainingVideos: [],
    accountVideos: [],
    lastFetchedTrainingVideos: null,
    settings: deepSpread({
      drawers: {
        manage: {
          width: 60,
          bootstrapSize: 'lg'
        }
      },
      forks: {
        manage: null,
        blueprints: null,
        nav: null,
        settings: false
      },
      backpackVersion: null,
      seShowDT: true,
      seShowCP: false,
      manageStatsPaneSize: 1,
      timeRangeShortcut: 'This Month',
      timeRangeCompare: false,
      timeRangeCompareOverride: {},
      showPlanStatus: 'Show All But Removed',
      showGuardrails: true,
      manageNavigationCollapsed: false,
      activeSegment: {
        savedSegmentId: 0,
        name: 'All Accounts'
      },
      channelGroupsFilter: [],
      includeReadOnlyAccounts: true,
      launchStatusShowCompleted: true,
      launchStatusShowRelaunches: true,
      showActiveBlueprintsOnly: true,
      blueprintEditorShowAdvancedSettings: false,
      tableToggleFilters: {
        budget: {
          explicitlyShared: false,
          showActiveOnly: true
        },
        campaign: {
          showActiveOnly: true
        }
      },
      blueprintEditor2: {
        navFilter: ''
      },
      metricsBarItems: null,
      distanceUnit: null,
      officeMateIntroDismissed: {
        deckMate: false,
        cellMate: false
      },
      showAthenaWarningForReporting: true,
      strictSegments: true,
      gridViewMedia: true,
      showBudgetPacingWarningForDataExplorer: true,
      showSearchQueryWarningForDataExplorer: true
    }, settings || {}),
    breadcrumbHeight: 0,
    copyBin: copyBin || null,
    config: {
      strategyEditorFields: {}
    },
    active: {
      reverseSyncs: []
    },
    customTimeRange: [], // defaultTimeRange,
    customer,
    customerState,
    navigationForUser: {},
    activeSettingsLink: {}
  },
  data: {
    user: null,
    // accounts: null,
    accountsById: null,
    activeItem: null,
    settingsItem: null,
    toast: [],
    accountListFilter: '',
    isLocalDev: null,
    actingAsUser,
    active: {
      account: {},
      accountNotifications: null,
      campaign: {},
      adgroup: {},
      ad: {},
      keyword: null,
      budgets: [],
      reportDefinition: null,
      reportDownload: [],
      isReportDownloading: false,
      accountPollingData: null
    },
    skeletons,
    templates: [],
    strategyEditor: {
      recipes: []
    },
    enumerations,
    availableAccounts: [],
    clipboard: {},
    overrides: [],
    advertisingChannels,
    partners,
    verticals,
    reportConfigurations: [],
    googleConfigReportFields: [],
    reportDefinitions,
    expandedWidget: null,
    usersForCustomer: [],
    keywordAnalysisSummary: [],
    broadcasts: [],
    customerBroadcasts: [],
    accountBroadcastDraftsForCustomer: [],
    loading: {},
    audiencePlans: [],
    activeAudiencePlan: null,
    jobStatus: {},
    launchPlans: [],
    navigationSearchTokens: [],
    navigationSearchTree: null,
    googleContentLinkStatus: null,
    googleMyBusinessLinkStatus: null,
    facebookPageLinkStatus: null,
    facebookAccountList: [],
    whiteLabel: {},
    accountPartnerTrackingCodes: [],
    blueprints: [], // basic data for blueprints so we can show linked status,
    blueprintEditing: {},
    customerSettings: [],
    campaignNavigation: {}, // accountPlanId: structuredCampaignNav = {}
    adGroupNavigation: {}, // campaignPlanId: adGroupNav = []
    timeZones,
    creativeSpecs: null,
    channelGroups: null,
    productScopePlans: {},
    accountQaStatus: {},
    userSavedSegments,
    savedSegmentFields,
    activelyRepublishingBlueprints: [],
    partnerPages: {},
    accountDataSaveInProgress: false,
    reportRequestResponse: null,
    questionnaireSpecs: null, // []
    adTemplateDataChoices: {},
    blueprintEditor2: {
      dynamicTags: {
        all: [],
        aliases: []
      }
    },
    peerInsights: {},
    accountPageInstantExperiences: {},
    instantExperienceLayouts: [],
    instantExperienceTemplates: [],
    layoutBuilderPath: []
  }
}

export default createStore({
  state () {
    return initialState
  },
  mutations: baseMutations,
  getters: baseGetters,
  actions: baseActions,
  modules: {
    bulkEdit,
    colab,
    createFlow,
    forum,
    mediaSelector,
    notifications,
    table
  },
  strict: false
})
