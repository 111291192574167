<script setup>
const props = defineProps({
  light: {
    type: Boolean,
    default: false
  },
  lazy: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <p-accordion :lazy="lazy">
    <template #collapseicon>
      <fluency-icon type="chevronUp" :class="{'text-white': props.light}"></fluency-icon>
    </template>
    <template #expandicon>
      <fluency-icon type="chevronDown" :class="{'text-white': props.light}"></fluency-icon>
    </template>
    <slot name="default"></slot>
  </p-accordion>
</template>
