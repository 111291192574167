export const Creative = (flujax) => ({
  getApprovalStatuses () {
    return flujax.post('/creative/getApprovalStatuses')
  },
  getApprovals () {
    return flujax.post('/creative/getApprovals')
  },
  approve (creativePlanIds = []) {
    if (Array.isArray(creativePlanIds) && creativePlanIds.length > 0) {
      return flujax.post('/creative/approve', creativePlanIds)
    }

    return false
  },
  disapprove (creativePlanIds = []) {
    if (Array.isArray(creativePlanIds) && creativePlanIds.length > 0) {
      return flujax.post('/creative/disapprove', creativePlanIds)
    }

    return false
  },
  remove (creativePlanIds = []) {
    if (Array.isArray(creativePlanIds) && creativePlanIds.length > 0) {
      return flujax.post('/creative/archive', creativePlanIds)
    }

    return false
  }
})
